import React from 'react';
import { createContext, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Layout from "../../components/layout";
import IndefiniteLoadingSpinner from "../../components/loading-spinner";
import { Button, Card, CardContent, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Check, Close, HelpOutline } from '@mui/icons-material';
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProgramStateLicensureStatusRecord, LicensureResearchDataReviewFeedback } from '../../api/models';
import { useEffect, useState } from 'react';
import { retrieveNextLicensureDataToReview, updateLicensureResearchDataReviewFeedback } from '../../api/research-data-service';
import { Result } from '../../api/result';
import { formatDate } from '../../common/utils';

const APPROVAL_STATUS = {
  APPROVED: "approved",
  REJECTED: "rejected",
  REVIEW_LATER: "review-later"
};

const WIDGET_STATES = {
  LOADING_DATA: 0,
  LOADED_DATA: 1,
}

interface ResearchDataContextType {
  loadNextRecord: () => void;
  setFeedback: (approval: string, comments: string) => void;
  saveFeedback: (approval: string, comments: string) => Promise<Result<void>>;
}

const ResearchDataContext = createContext<ResearchDataContextType>({
  loadNextRecord: () => {},
  setFeedback: (approval: string, comments: string) => {},
  saveFeedback: (approval: string, comments: string): Promise<Result<void>> => { return Promise.resolve({ ok: false, error: { message: "Not implemented" }, data: undefined }); },
});

const useResearchDataContext = () => {
  const context = useContext(ResearchDataContext);
  if (context === undefined) {
    throw new Error('useResearchData must be used within a ResearchDataProvider');
  }
  return context;
};

const LicensureResearchData: React.FC = () => {
  const [researchData, setResearchData] = useState<ProgramStateLicensureStatusRecord | null>(null);
  const [widgetState, setWidgetState] = useState<number>(WIDGET_STATES.LOADING_DATA);
  const { submissionId } = useParams();

  // TODO: Fix.  Using this as a shortcut to reuse getNextRecord logic
  let resumeKeyDefault = "0"
  if (submissionId) {
    resumeKeyDefault = (parseInt(submissionId, 10) - 1).toString();
  }

  const [resumeKey, setResumeKey] = useState<string>(resumeKeyDefault);  

  const navigate = useNavigate();

  function loadNextRecord() {
    if (researchData) {
      setResumeKey(researchData.id);
      setResearchData(null);
      setWidgetState(WIDGET_STATES.LOADING_DATA);
    }
  }

  function setFeedback(approval: string, comments: string) {
    console.log("Feedback:", approval, comments);
  }

  async function saveFeedback(approval: string, comments: string): Promise<Result<void>> {
    if (!researchData) {
      console.error("No research data to save feedback");
      return Promise.resolve({ ok: false, error: { message: "No research data to save feedback" }, data: undefined });
    }

    console.log("Saving feedback for submission:", researchData.id, approval, comments);
    let feedback = new LicensureResearchDataReviewFeedback(researchData.id, approval, comments);
    try {
      const result = await updateLicensureResearchDataReviewFeedback(feedback);
      if (result.ok) {
        navigate(-1)
      }
      return result;
    } catch (err) {
      const errorMessage = err instanceof Error ? err.toString() : "Error saving feedback: Unknown error";
      return { ok: false, error: { message: errorMessage }, data: undefined };
    }
  }

  const ResearchDataContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
      <ResearchDataContext.Provider value={{ loadNextRecord, setFeedback, saveFeedback }}>
        {children}
      </ResearchDataContext.Provider>
    );
  };
  
  useEffect(() => {
    retrieveNextLicensureDataToReview(resumeKey)
      .then(apiResult => {
        setWidgetState(WIDGET_STATES.LOADED_DATA);

        if (!apiResult) {
          setResearchData(null);
          return;
        }

        if (apiResult?.ok) {
          setResearchData(apiResult.data);
        } else {
          // TODO: Handle error
          console.error("Error fetching research data:", apiResult?.error?.message);
          setResearchData(null);
        }
      })
      .catch(error => {
        console.error("Error fetching research data:", error);
      });
    return () => { };
  }, [resumeKey]);

  return (
    <>
      <Layout pageName="research-data">
        <Grid container lg={6} md={6} xs={10} className="research-data-container">
          <Grid item xs={12}>
            <Card>
              <ResearchDataContextProvider>
                <ContentByState widgetState={widgetState} researchData={researchData} loadNextRecord={loadNextRecord} />
              </ResearchDataContextProvider>
            </Card>
          </Grid>
        </Grid>
      </Layout>
    </>
  );
  
};

const ContentByState: React.FC<{ widgetState: number, researchData: ProgramStateLicensureStatusRecord | null, loadNextRecord: () => void }> = ({ widgetState, researchData, loadNextRecord }) => {
  switch (widgetState) {
    case WIDGET_STATES.LOADING_DATA:
      return (
        <CardContent>
        <IndefiniteLoadingSpinner />
        </CardContent>
      );

    case WIDGET_STATES.LOADED_DATA:
      return (researchData ? (
        <>
          <ProgramStateLicenceResearchDataSection
            researchData={researchData} />
          <ReferenceDataSection referenceData={researchData.referenceData} />
          <ReviewFeedbackSection />
        </>) : <div>No Records</div>);
    
    default:
      return (<div>Error loading data</div>);
  }
}

const ProgramStateLicenceResearchDataSection: React.FC<{ researchData: ProgramStateLicensureStatusRecord }> = ({ researchData }) => {
  return (
    <>
      <CardContent>
        <Stack spacing={2} alignItems="center">
          <Typography variant="h6">{ researchData.program.providerOrg.name } {/*({ researchData.program.providerOrg.state.code })*/}</Typography>
          <Typography variant="h6">{researchData.program.programName}</Typography>
            <LVTable>
              <LVItem label="Last Updated Date" value={ formatDate(researchData.lastUpdatedDate) } />
              <LVItem label="Licensure State" value={ researchData.stateLicensureStatus.state.code } />
              <LVItem label="Meet Licensure Requirements?" value={ researchData.stateLicensureStatus.meetRequirements ? "Yes" : "No" } />
            </LVTable>
        </Stack>
      </CardContent>
    </>
  );
}

const ReviewFeedbackSection: React.FC = () => {
  const [approval, setApproval] = React.useState<string>("review-later");
  const { loadNextRecord, saveFeedback } = useResearchDataContext();
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [saveError, setSaveError] = React.useState<string | null>(null);

  const handleApprovalChange = (newValue: string) => {
    setApproval(newValue);
  };

  const schema = yup.object().shape({
    approval: yup.string().required("Approval status is required"),
    comments: yup.string().when("approval", () => {
      return approval === APPROVAL_STATUS.REJECTED ? yup.string().required("Comments are required when rejected") : yup.string();
    }),
  });

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { approval, comments: "" },
  });

  const onSubmit = async (data: any) => {
    setSaveError(null);
    setIsSaving(true);
    let result = await saveFeedback(data.approval, data.comments);
    setIsSaving(false);
    if (!result.ok) {
      setSaveError(result.error?.message ?? null);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardContent>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography variant="h6">Approval</Typography>
        </Stack>
        <Stack direction="row" justifyContent="center">
          <Controller
            name="approval"
            control={control}
            render={({ field }) => (
              <ApprovalToggleGroup
                value={field.value}
                disable={isSaving}
                onChange={(value) => {
                  field.onChange(value);
                  handleApprovalChange(value);
                }}
              />
            )}
          />
        </Stack>
        {errors.approval && <Typography color="error">{errors.approval.message}</Typography>}
        {approval === APPROVAL_STATUS.REJECTED && (
          <Stack direction="row" justifyContent="center" alignItems="center">
            <Controller
              name="comments"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Comments"
                  multiline
                  rows={4}
                  variant="outlined"
                  fullWidth
                  error={!!errors.comments}
                  helperText={errors.comments?.message}
                  disabled={isSaving}
                />
              )}
            />
          </Stack>
        )}
      </CardContent>
      <CardContent>
        <Stack direction="row" spacing={2} justifyContent="center">
            <Button type="submit" variant="contained" disabled={isSaving}>Save</Button>
            <Button variant="contained" onClick={() => loadNextRecord()} disabled={isSaving}>Skip</Button>
        </Stack>
      </CardContent>

       {/* Status Section */}
      <CardContent> 
        <Stack direction="row" spacing={2} justifyContent="center">
          {isSaving && <Typography color="success">Saving...</Typography>}
          {!isSaving && saveError && <Typography color="error">{saveError}</Typography>}
        </Stack>
      </CardContent>
    </form>
  );
};

interface ReferenceDataType {
  url: string;
  note: string;
}

const ReferenceDataSection: React.FC<{ referenceData: ReferenceDataType[] }> = ({ referenceData }) => {
  return (
    <>
      <CardContent>
        <Stack direction="row" justifyContent="center" alignItems="center">
          <Typography variant="h6">Reference</Typography>
        </Stack>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>URL</TableCell>
                <TableCell>Note</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {referenceData.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <a href={item.url} onClick={(e) => {
                      e.preventDefault();
                      window.open(item.url, '_blank', 'noopener,noreferrer');
                    }}>
                      {item.url}
                    </a>
                  </TableCell>
                  <TableCell>{item.note}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
    </>
  );
};

const LVTable: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <>
      <Table size="small" sx={{ width: 'auto', tableLayout: 'auto' }}>
        <TableBody>
          {children}
        </TableBody>
      </Table>
    </>
  );
};

const LVItem: React.FC<{ label: string, value: string }> = ({ label, value }) => {
  return (
    <>
      <TableRow>
        <TableCell sx={{ borderBottom: 'none', fontWeight: 'bold', textAlign: 'right' }}>{label}</TableCell>
        <TableCell sx={{ borderBottom: 'none' }}>{value}</TableCell>
      </TableRow>
    </>
  );
};

const ApprovalToggleGroup: React.FC<{ value: string, onChange: (value: string) => void, disable?: boolean }> = ({ value, onChange, disable = false }) => {
  const handleToggle = (event: React.MouseEvent<HTMLElement>, newValue: string) => {
    if (newValue !== null) {
      onChange(newValue);
    }
  };

  return (
    <ToggleButtonGroup
      value={value}
      exclusive
      onChange={handleToggle}
      aria-label="approval status"
      disabled={disable}
    >
      <ToggleButton value={APPROVAL_STATUS.APPROVED} aria-label="approved" size="small">
        <Check sx={{ color: 'green' }} />
        Approve
      </ToggleButton>
      <ToggleButton value={APPROVAL_STATUS.REJECTED} aria-label="rejected" size="small">
        <Close sx={{ color: 'red' }} />
        Reject
      </ToggleButton>
      <ToggleButton value={APPROVAL_STATUS.REVIEW_LATER} aria-label="review later" size="small">
        <HelpOutline sx={{ color: 'orange' }} />
        Review Later
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

export default LicensureResearchData;
